<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{ this.messagetype.title_jp }}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{ name: 'Dashboard' }">
                ダッシュボード
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'Admin message',
                  params: { type: message_type },
                }"
                >{{ this.messagetype.title_jp }}</router-link
              >
            </li>
            <li><span>詳細</span></li>
          </ul>
        </nav>
      </div>
      <!-- <SuccessMessage v-if="successMessage" /> -->

      <div
        v-if="messagecolumn['admin_edit']"
        class="toggle_wrap"
      >
        <div class="toggle_dsn">
          <span class="modeon">表示</span>

          <router-link
            :to="{
              name: 'Admin messageedit',
              params: { type: message_type, id: message_id },
            }"
            >編集</router-link
          >
        </div>
        <!--toggle_dsn-->
      </div>
      <!-- Edit button for Photolog -->
      <div
        v-if="messagecolumn['admin_edit'] && this.message_type == 'photolog'"
        class="toggle_wrap"
      >
        <div class="toggle_dsn">
          <span class="modeon">表示</span>

          <router-link
            :to="{
              name: 'Admin messageedit',
              params: { type: message_type, id: message_id },
            }"
            >編集</router-link
          >
        </div>
        <!--toggle_dsn-->
      </div>

      <div class="command_wrap command_bottom">
        <div class="leftarea btn_wrap">
          <div class="return_link">
            <router-link
              :to="{ name: 'Admin message', params: { type: message_type } }"
            >
              <span class="icon"><i class="fas fa-chevron-left"></i></span
              >{{ this.messagetype.title_jp }}一覧
            </router-link>
          </div>
          <div></div>
        </div>
        <!--leftarea-->
        <div class="rightarea">
          <!--commandtext_wrap-->
        </div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->
      <!-- <Spinner v-if="spinner" /> -->

      <div class="contentout_wrap">
        <div class="content_wrap twocolumn">
          <div class="box_wrap left">
            <h2 class="ttl_2">{{ this.messagetype.title_jp }}詳細</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="formtable">
                  <tbody>
                    <tr v-if="message_type == 'mysdgs'">
                      <th class="wid_200px">対応目標</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showmessage.goalType }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="messagecolumn['title']">
                      <th class="wid_200px">{{ messagecolumn["title"] }}</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showmessage.title }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="message_type !== 'sdgsgoal'">
                      <th class="wid_200px">本文</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showmessage.content"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>

                    <template v-if="message_type === 'sdgsgoal'">
                      <tr>
                        <th class="wid_200px"></th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p class="bold">目標</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p class="bold">実績</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p class="bold">達成率(%)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th class="wid_200px">通期目標</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ SdgsGoal.goal }} 件</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ SdgsGoal.longTermReportCount }} 件</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ SdgsGoal.longTermRatio + '%' }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-bottom-none" v-for="month, index in SdgsGoal.months" :key="index">
                        <th class="wid_200px">{{ month.name }}</th>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ month.goal }} 件</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ month.reportCount }} 件</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="form_outwrap">
                            <div class="wrap wid_100per p_r0 m_b0">
                              <div class="in_wrap">
                                <div class="show_body_post default">
                                  <p>{{ month.ratio + '%' }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>   
                    </template>
                                  

                    <tr v-if="messagecolumn['target_ids']">
                      <th class="wid_200px">
                        {{ messagecolumn["target_ids"] }}
                      </th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div
                              v-for="user in showmessage.target_user"
                              :key="user.id"
                              class="in_wrap"
                            >
                              <p class="f_500">
                               <!-- {{ user.last_name }}{{ user.first_name }} --><!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                                 {{ user.deleted_at ?  user.last_name + user.first_name +' (削除済み) ': user.last_name + user.first_name }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="messagecolumn['tags'] && message_type !== 'sdgsgoal' && message_type !== 'mysdgs'">
                      <th class="wid_200px">{{ messagecolumn["tags"] }}</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div
                              v-for="tag in showmessage.tags"
                              :key="tag.id"
                              class="in_wrap"
                            >
                              <p class="f_500">{{ tag.name }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr v-if="messagecolumn['thanks_type']">
                      <th class="wid_200px">
                        {{ messagecolumn["thanks_type"] }}
                      </th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div
                              v-for="thanks in showmessage.thanks_type"
                              :key="thanks.id"
                              class="in_wrap"
                            >
                              <p class="f_500">{{ thanks.value }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr v-if="messagecolumn['group']">
                      <th class="wid_200px">{{ messagecolumn["group"] }}</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <div v-if="showmessage.group && !(messagecolumn['photolog'])">
                                <p class="f_500">{{ showmessage.group }}</p>
                              </div>
                              <div v-if="showmessage.group && messagecolumn['photolog']">
                                <p class="f_500">{{ showmessage.group }}</p>
                              </div>
                              <div v-if="!(showmessage.group) && messagecolumn['photolog']">
                                <p class="f_500">{{ showmessage.deparment }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr v-if="messagecolumn['sdgs_type']">
                      <th class="wid_200px">
                        {{ messagecolumn["sdgs_type"] }}
                      </th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showmessage.sdgs_type }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr v-if="messagecolumn['photolog']">
                      <th class="wid_200px">{{ messagecolumn["photolog"] }}</th>
                      <td>
                        <img id="img_tag_id" />
                      </td>
                    </tr>

                    <tr
                      v-if="
                        messagecolumn['attached_file'] &&
                        showmessage.attached_file
                      "
                    >
                      <th class="wid_200px">関連資料</th>
                      <td>
                        <div class="row_form_wrap">
                          <div class="row_wrap header">
                            <div class="col">
                              <p class="ttl_3">タイトル</p>
                            </div>
                            <div class="col">
                              <p class="ttl_3">アップロードファイル</p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="row_form_wrap m_b20"
                          v-for="file in showmessage.attached_file"
                          :key="file.id"
                        >
                          <div class="row_wrap">
                            <div class="col">
                              <div class="form_outwrap">
                                <div
                                  class="wrap wid_100per celltxtarea m_b0 p_r0"
                                >
                                  <div class="in_wrap">
                                    <p class="f_500">
                                      {{ file.display_name }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <!--form_outwrap-->
                            </div>
                            <div class="col item_list">
                              <div class="form_outwrap">
                                <div
                                  class="wrap wid_100per celltxtarea m_b0 p_r0"
                                >
                                  <div class="in_wrap">
                                    <a
                                      style="cursor: pointer"
                                      @click="
                                        getFileStoreage({
                                          file: file.storage_file_name,
                                          file_name: file.updaload_name,
                                        })
                                      "
                                      target="_blank"
                                      class="links"
                                      >{{ file.updaload_name }}</a
                                    >
                                  </div>
                                </div>
                              </div>
                              <!--form_outwrap-->
                            </div>
                          </div>
                          <!--row_wrap-->
                        </div>
                        <!-- <span v-else>関連資料なし</span> -->
                      </td>
                    </tr>
                    <tr v-if="message_type == 'daily'">
                      <th class="wid_200px">コメント</th>
                      <td v-if="reply_messages != null">
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <table>
                              <tr
                                v-for="(reply_message, index) in reply_messages"
                                :key="reply_message.id"
                                class="in_wrap"
                              >
                                <td>{{ index + 1 }}</td>
                                <td v-if="reply_message.from_user">
                                  {{ reply_message.from_user.last_name
                                  }}{{ reply_message.from_user.first_name }}
                                </td>
                                <td>{{ reply_message.content }}</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--formtable-->
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap -->
          </div>
          <!--box_wrap-->
          <div class="box_wrap right">
            <h2 class="ttl_2">公開情報</h2>
            <div class="form_outwrap">
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開ステータス</p></div>
                <div class="in_wrap">
                  <p>{{ showmessage.status }}</p>
                </div>
              </div>
              <!--wrap-->

              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開日付</p></div>
                <div class="in_wrap">
                  <p>{{ formatDate_time(showmessage.published_at) }}</p>
                </div>
              </div>
              <!--wrap-->
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">記事登録</p></div>
                <div class="in_wrap">
                  <p v-if="showmessage.from_user">
                    登録日： {{ formatDate_time(showmessage.created_at) }}
                    <br />作成者：
                    <!-- {{ showmessage.from_user.last_name }}{{ showmessage.from_user.first_name }} --><!--OTHER-4884 : 表示不整合箇所の修正対応  -->
                    {{ showmessage.from_user.deleted_at ?  showmessage.from_user.last_name + showmessage.from_user.first_name +' (削除済み) ': showmessage.from_user.last_name + showmessage.from_user.first_name }}
                  </p>
                </div>
              </div>
              <!--wrap-->
              <!-- <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">最終更新</p></div>
                <div class="in_wrap">
                  <p>
                    更新日：{{ formatDate_time(showmessage.updated_at) }}
                    <br />作成者：{{ userFullName }}
                  </p>
                </div>
              </div> -->
              <!--wrap-->
            </div>
            <!--form_outwrap-->
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from "jquery";
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from "dayjs";
import { mapState, mapGetters, mapActions } from "vuex";
import SDGs from "../../../apis/SDGs";

export default {
  name: "message",
  data() {
    return {
      userlists: "",
      showmessage: {},
      MessageColumn() {
        return MessageColumn;
      },
      messagecolumn: {},
      MessageType() {
        return MessageType;
      },
      messagetype: {},
      files: {},
      approval_ng: {},
      approval_post_data: {},
      reply_messages: {},
      SdgsGoal: {
        goal: 0,
        option: '',
        months: [],
      }
    };
  },
  components: {},
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = this.$route.params.type;
    this.message_id = this.$route.params.id;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.ShowMessage(this.message_id);
    this.getFile(this.message_id);
    if (this.message_type == "daily") {
      this.replyList(this.message_id);
    }
  },
  mounted() {
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    $("figure.media").each(function () {
      var $y_url = $(this)
        .children()
        .attr("url")
        .replace("https://youtu.be/", "");
      $(this)
        .children()
        .replaceWith(
          "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
        );
      $(this)
        .children(".post_youtube")
        .attr("src", "https://www.youtube.com/embed/" + $y_url);
    });
  },
  methods: {
    ...mapActions("event", ["viewEvent"]),
    ...mapActions("file", ["getFileEvent", "getFileStoreage"]),
    ...mapActions("common", ["setSpinnerStatus"]),
    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    formattime: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD hh:mm:ss"),
    ShowMessage(id) {
      message
        .show(id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;

            if (
              this.message_type == "challenge" ||
              this.message_type == "sdgs" || 
              this.message_type == "sdgsgoal" ||
              this.message_type == "mysdgs"
            ) {
              this.showmessage.option = "";
              var contentSplit = JSON.parse(this.showmessage.content);

              if (this.message_type == "sdgsgoal") {
                this.SdgsGoal.goal = contentSplit.content
                this.SdgsGoal.option = contentSplit.option
                this.SdgsGoal.months = contentSplit.months

                SDGs.sdgsReportCount(contentSplit.finYear).then((res)=>{
                  if (res.data) {
                    this.SdgsGoal.longTermReportCount = 0
                    this.SdgsGoal.longTermReportCount = res.data.long_term_count
                    this.SdgsGoal.longTermRatio = 0

                    if (Number(this.SdgsGoal.goal) !== 0) {
                      this.SdgsGoal.longTermRatio = Math.round((this.SdgsGoal.longTermReportCount/this.SdgsGoal.goal)*100);

                      // if (this.SdgsGoal.longTermRatio > 150) {
                      //   this.SdgsGoal.longTermRatio = 150
                      // }
                    }

                    let reportCount = 0;
                    this.SdgsGoal.months.forEach((el) => {
                      reportCount = res.data.short_term_count.find(obj => obj.name === el.name).count;
                      el.reportCount = reportCount
                      el.ratio = 0;
                      if (Number(el.goal) !== 0) {
                        el.ratio = Math.round((el.reportCount/el.goal)*100);
                      }
                    });
                  }else{
                    this.$router.push({ name: "Front Error Catch" });
                  }
                }).catch((err)=>{
                  console.error(err);
                  this.$router.push({ name: "Front Error Catch" });
                })
              }else{
                var TermoOption = contentSplit;
                this.showmessage.option = TermoOption["option"];
                this.showmessage.content = TermoOption["content"];
                this.showmessage.content_1 = TermoOption["content_1"];
                this.showmessage.content_2 = TermoOption["content_2"];
                this.showmessage.month = TermoOption["month"];
                if (this.message_type == "mysdgs") {
                  this.showmessage.goalType = TermoOption["goalType"];
                }
              }
            }

            //Jquery
            // youtubeがタグの構成上表示出来ないので以下でタグを変換している
            $(function () {
              $("figure.media").each(function () {
                var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
                $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
                $(this)
                  .children()
                  .replaceWith(
                    "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
                  );
                  console.log($y_url);
                $(this)
                  .children(".post_youtube")
                  .attr("src", "https://www.youtube.com/embed/" + $y_url);
              });
            });
            this.getMessageMeta(id);
            this.getMessageEyecatch(id);
            this.getMessageAttachedFile(id);
            if (this.showmessage.from_id != null) {
              this.getFromUser(this.showmessage.from_id);
            }
            if (this.showmessage.target_ids != null) {
              this.getTargetUser(this.showmessage.target_ids);
            }
            // console.log(this.showmessage.attached_file);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    getFromUser(user_id) {
      user
        .show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    getTargetUser(user_ids) {
      this.showmessage.target_user = [];
      user_ids.forEach((user_id) => {
        user
          .show(user_id)
          .then((response) => {
            if (response != null) {
              this.showmessage.target_user.push(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      });
    },
    getMessageMeta(message_id) {
      if (this.messagecolumn["admin_edit"]) {
        message
          .showmetakey(message_id, "key", "from_admin")
          .then((response) => {
            if (response != null) {
              this.showmessage.from_admin = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }

      if (this.messagecolumn["sdgs_type"]) {
        message
          .showmetakey(message_id, "key", "sdgs_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.sdgs_type = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["group"]) {
        message
          .showmetakey(message_id, "key", "group")
          .then((response) => {
            if (response != null) {
              this.showmessage.group = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["public"]) {
        message
          .showmetakey(message_id, "key", "public")
          .then((response) => {
            if (response != null) {
              this.showmessage.public = response.data.data[0].value;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
      if (this.messagecolumn["thanks_type"]) {
        message
          .showmetakey(message_id, "key", "thanks_type")
          .then((response) => {
            if (response != null) {
              this.showmessage.thanks_type = response.data.data;
              console.log(this.showmessage.thanks_type);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },

    getMessageEyecatch(message_id) {
      if (this.messagecolumn["photolog"]) {
        message
          .showfile(message_id, "key", "photolog")
          .then((response) => {
            if (response != null) {
              this.showmessage.photolog = response.data.data[0];
              let fileApiPass =
                "/api/v1/file/" + this.showmessage.photolog.storage_file_name;
              console.log(fileApiPass);
              let img = document.getElementById("img_tag_id");
              img.src =
                "data:image/jpeg;base64," + this.showmessage.photolog.base64;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    getMessageAttachedFile(message_id) {
      if (this.messagecolumn["attached_file"]) {
        message
          .showfile(message_id, "key", "attached_file")
          .then((response) => {
            if (response.data.data.length != 0) {
              this.showmessage.attached_file = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
    getFile(message_id) {
      message
        .showfile(message_id, "key", "attached_file")
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
              console.log(this.files);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch(() => {
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
    dlfile(path) {
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          window.open(window.URL.createObjectURL(blob));
        });
    },
    replyList(id) {
      this.loader = true;
      message
        .listChild(id, "daily_res")
        .then(
          (response) => {
            if (response != null) {
              this.reply_messages = response.data.data;

              this.reply_messages.forEach((reply_message) => {
                user
                  .show(reply_message.from_id) //投稿者の取得
                  .then((response) => {
                    if (response != null) {
                      reply_message.from_user = response.data.data;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});

                // message.getMeta(ob.id)//metaの取得
                // .then((response) => {
                //   if (response != null) {
                //     if(response.data.data[0]){
                //       this.reply_messages[i].reply_com_id = response.data.data[0].value;
                //       console.log(response.data.data[0].value);
                //     }
                //   }
                // })
                // .catch((error) => {
                //   console.log(error);
                // })
                // .finally(() => {
                // });
              });

              console.log(this.reply_messages);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
.border-bottom-none{
  border-bottom: none !important;
}
</style>
